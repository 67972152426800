import { useTranslation } from 'react-i18next';
import '@material/react-top-app-bar/dist/top-app-bar.css';
import '@material/react-material-icon/dist/material-icon.css';
import TopAppBar, {
    TopAppBarIcon,
    TopAppBarRow,
    TopAppBarSection,
    TopAppBarTitle} from '@material/react-top-app-bar';
import './i18n';
import { useCallback, useEffect, useState } from 'react';
import {isMobile} from 'react-device-detect';
import {
    Menu,
    MenuItem,
    MenuHeader,
    MenuDivider,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/zoom.css';
import axios from 'axios'

const availableLangs = ['uk', 'ru', 'en'];
const currencyPriority = ['UAH', 'USD', 'EUR'];
const defaultLangCode = availableLangs[0];
const notFoundCode = 'en';

function MenuComponent() {
    const { t, i18n } = useTranslation();

    const [lang, setLang] = useState(localStorage.getItem('locale') ?? defaultLangCode);

    const setLocale = useCallback((newLocale) => {
        localStorage.setItem('locale', newLocale);
        setLang(newLocale);
        i18n.changeLanguage(newLocale);

        document.documentElement.dir = 'ltr';
    }, [setLang, i18n]);

    const getGeoInfo = useCallback(() => {
        if(localStorage.getItem('locale'))
        {
            return;
        }
        axios.get('https://ipapi.co/json/').then((response) => {
            const langsInPriority = response.data?.languages?.split(/[\s,-]+/) ?? [];
            for(const lang of langsInPriority)
            {
                if(availableLangs.includes(lang.toLowerCase()))
                {
                    setLocale(lang);
                    return;
                }
            }
            for(let i = 0; i < currencyPriority.length; i++)
            {
                if(response.data.currency === currencyPriority[i])
                {
                    const lang = availableLangs[i];
                    setLocale(lang);
                    return;
                }
            }
            setLocale(notFoundCode);
        }).catch((error) => {
            console.log(error);
            setLocale(defaultLangCode);
        });
    }, [setLocale]);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [i18n, lang]);

    useEffect(() => {
        getGeoInfo();
    }, [getGeoInfo]);

    const navigateTo = (itm) => {
        document.getElementById(itm)?.scrollIntoView();
    };

    return isMobile
        ? (
            <TopAppBar style={{
                backgroundColor: 'rgba(24,0,24,1)',
            }}>
                <TopAppBarRow>
                    <TopAppBarSection align='start'>
                        <div className="t454__logowrapper">
                            <div className="t454__logowrapper2">
                                <div style={{display: 'block'}}>
                                    <img className="t454__imglogo t454__imglogomobile" src="resources/neurophoto.png" imgfield="img" 
                                        style={{maxWidth: 180, width: 180}} alt="" />
                                </div>
                            </div>
                        </div>
                    </TopAppBarSection>
                    <TopAppBarSection align='end'>
                            <Menu menuButton={<button type="button"
                                style={{marginRight: 18}}
                                className="t-menuburger t-menuburger_first" 
                                aria-expanded="false">
                                    <span style={{backgroundColor: 'white'}}></span>
                                    <span style={{backgroundColor: 'white'}}></span>
                                    <span style={{backgroundColor: 'white'}}></span>
                                    <span style={{backgroundColor: 'white'}}></span>
                                </button>}
                                menuStyle={{
                                    backgroundColor: 'black',
                                    marginRight: 18,
                                    paddingLeft: '0px !important',
                                    borderRadius: 2,
                                    top: 28,
                                }}
                            >
                                <MenuItem style={{background: 'black', color: 'white'}} className='t-menu__link-item' onClick={() => {navigateTo('rec639744688');}}>{t('menu_about')}</MenuItem>
                                <MenuItem style={{background: 'black', color: 'white'}} className='t-menu__link-item' onClick={() => {navigateTo('portfolio');}}>{t('menu_portfolio')}</MenuItem>
                                <MenuItem style={{background: 'black', color: 'white'}} className='t-menu__link-item' onClick={() => {navigateTo('rec639744704');}}>{t('menu_cost')}</MenuItem>
                                <MenuItem style={{background: 'black', color: 'white'}} className='t-menu__link-item' onClick={() => {navigateTo('rec639744717');}}>{t('menu_about_us')}</MenuItem>
                                <MenuItem style={{background: 'black', color: 'white'}} className='t-menu__link-item' onClick={() => {navigateTo('contacts');}}>{t('menu_contacts')}</MenuItem>
                                <MenuDivider />
                                <MenuHeader>{t('available_langs')}</MenuHeader>
                                {availableLangs.map(e => (
                                    <MenuItem
                                        style={{background: 'black', color: e===lang ? 'unset' : 'white'}} 
                                        className='t-menu__link-item'
                                        key={'key_lang_'+e}
                                        onClick={() => {
                                            setLang(e);
                                        }}
                                        disabled={e===lang}
                                    >
                                        {t('lang_' + e)}
                                    </MenuItem>
                                ))}
                            </Menu>
                    </TopAppBarSection>
                </TopAppBarRow>
            </TopAppBar>
        )
        : (
        <TopAppBar style={{
            backgroundColor: 'rgba(24,0,24,1)',
        }}>
            <TopAppBarRow>
                <TopAppBarSection align='start'>
                    <TopAppBarTitle onClick={() => {navigateTo('rec639744688');}} style={{cursor: 'pointer'}}>
                        {t('menu_about')}
                    </TopAppBarTitle>
                </TopAppBarSection>
                <TopAppBarSection align='start'>
                    <TopAppBarTitle onClick={() => {navigateTo('portfolio');}} style={{cursor: 'pointer'}}>
                        {t('menu_portfolio')}
                    </TopAppBarTitle>
                </TopAppBarSection>
                <TopAppBarSection style={{width: 220}}>
                    <TopAppBarIcon>
                        <div className="t454__logowrapper">
                            <div className="t454__logowrapper2">
                                <div style={{display: 'block'}}>
                                    <img className="t454__imglogo t454__imglogomobile" src="resources/neurophoto.png" imgfield="img" style={{maxWidth: 220, width: 220}} alt="" />
                                </div>
                            </div>
                        </div>
                    </TopAppBarIcon>
                </TopAppBarSection>
                <TopAppBarSection align="end">
                    <TopAppBarTitle onClick={() => {navigateTo('rec639744704');}} style={{cursor: 'pointer'}}>
                        {t('menu_cost')}
                    </TopAppBarTitle>
                </TopAppBarSection>
                <TopAppBarSection align="end">
                    <TopAppBarTitle onClick={() => {navigateTo('rec639744717');}} style={{cursor: 'pointer'}}>
                        {t('menu_about_us')}
                    </TopAppBarTitle>
                </TopAppBarSection>
                <TopAppBarSection align="end">
                    <TopAppBarTitle onClick={() => {navigateTo('contacts');}} style={{cursor: 'pointer'}}>
                        {t('menu_contacts')}
                    </TopAppBarTitle>
                </TopAppBarSection>
                <TopAppBarSection align="end">
                    <select id="localization-switcher" className="locale-switcher" value={lang} onChange={(e) => {
                        setLocale(e.target.value);
                    }}>
                        <option value="uk">{t('lang_uk')}</option>
                        <option value="en">{t('lang_en')}</option>
                        <option value="ru">{t('lang_ru')}</option>
                    </select>
                </TopAppBarSection>
                {/**/}
            </TopAppBarRow>
        </TopAppBar>
    );
}

export default MenuComponent;